<template>
  <div>
    <div class="dashboard__container pb-4 pt-2" v-if="eventInfo && eventInfo.id">
      <span class="flex align-start flex-wrap">
        <router-link :to="`/orders/${$route.params.id}/setup/details`">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isDetails, 'btn__dark btn__small': !isDetails }">Order Details</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/setup/instructions`">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isInstructions, 'btn__dark btn__small': !isInstructions }">Staff Instructions</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/setup/jobs`">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isJobs, 'btn__dark btn__small': !isJobs }">Jobs</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/setup/shifts`">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isShifts, 'btn__dark btn__small': !isShifts }">Manage Shifts</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/setup/addshift`">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isAddShift, 'btn__dark btn__small': !isAddShift }">Add Shift</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/setup/qr`">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isQR, 'btn__dark btn__small': !isQR }">QR Clode</button>
        </router-link>
        <button v-if="isShift" class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isShift, 'btn__dark btn__small': !isShift }" @click="goBack()">Go Back </button>

      </span>
    </div>
    <div class="dashboard__container pb-0 pt-0">
      <Loader v-if="!eventInfo || !eventInfo.id" />
      <!-- <router-view :key="$route.params.id" :eventInfo="eventInfo" :eventShifts="eventShifts" :eventShiftsDays="eventShiftsDays" :eventUsers="eventUsers" /> -->
      <router-view :key="$route.params.id" :eventInfo="eventInfo" />
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'

export default {
  name: 'orderHome',
  computed: {
    // ...mapState(['currentUser', 'userProfile', 'eventInfo', 'eventShifts', 'eventShiftsDays', 'eventUsers']),
    ...mapState(['currentUser', 'userProfile', 'eventInfo']),
    isDetails() {
      return this.$route.name == 'orderDetails';
    },
    isInstructions() {
      return this.$route.name == 'orderInstructions';
    },
    isJobs() {
      return this.$route.name == 'orderJobs';
    },
    isShifts() {
      return this.$route.name == 'orderShifts';
    },
    isShift() {
      return this.$route.name == 'orderShift';
    },
    isAddShift() {
      return this.$route.name == 'addShift';
    },
    isQR() {
      return this.$route.name == 'orderQR';
    },
  },
  components: {
    Loader,
  },
  methods: {
    goBack() {
      router.go(-1)
    },
    
  },
}

</script>